import FF from "../../../components/forms/FormField.module.css";

import type { MetalStatus } from "@warrenio/api-spec/spec.oats.gen";
import { Radio, RadioGroup } from "react-aria-components";
import { MetalStatusBadge } from "../../../modules/metal/status/MetalStatus.tsx";
import type { MetalStatusItem } from "../../../modules/metal/status/StatusLabels.tsx";
import { cn } from "../../../utils/classNames.ts";

interface StatusSelectorProps {
    items: MetalStatusItem[];
    value: MetalStatus;
    onChange: (value: MetalStatus) => void;
}

export function StatusSelector({ items, value, onChange }: StatusSelectorProps) {
    const radios = items
        .filter((item) => item.id !== "deleted")
        .map((item) => {
            const { id, description } = item;
            return (
                <Radio key={id} value={id} className={cn(FF.FormFieldIconRadio)}>
                    <div className="flex flex-col items-center gap-1">
                        <MetalStatusBadge reverse={value === id} value={id} />
                        <div className="font-size-small">{description}</div>
                    </div>
                </Radio>
            );
        });

    return (
        <RadioGroup
            aria-label="Select machine status"
            className="p-2 grid w-58em grid-cols-[repeat(auto-fit,11em)] gap-1"
            value={value}
            onChange={(v) => onChange(v as MetalStatus)}
        >
            {radios}
        </RadioGroup>
    );
}
