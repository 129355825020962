import { EditableButton } from "../../../components/forms/EditableButton.tsx";
import { WModal } from "../../../components/modal/WModal.tsx";
import { MetalStatusBadge } from "../../../modules/metal/status/MetalStatus.tsx";
import { LeaseModal } from "./LeaseModal.tsx";
import type { AdminMachineWithOs } from "./machinesWithOs.ts";

export function StatusEditButton({ item }: { item: AdminMachineWithOs }) {
    return (
        <WModal
            button={
                <EditableButton action={undefined}>
                    <MetalStatusBadge value={item.status} />
                </EditableButton>
            }
        >
            <LeaseModal machine={item} />
        </WModal>
    );
}
