import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type {
    AdminMetalLeaseCreateBody,
    AdminMetalLeaseUpdateBody,
    AdminMetalMachineCreateBody,
    AdminMetalMachineItem,
    AdminMetalMachineUpdateBody,
} from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { type ApiClient, getResponseData } from "../../../modules/api/apiClient.ts";
import { atomAllLocationsQuery } from "../../../modules/location/atomAllLocationsQuery.ts";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { adminMutation } from "../../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../../utils/query/queryAtom.ts";

//#region Response

/** Bare metal with extra `type` property */
export interface AdminMetalMachineItemLoc extends AdminMetalMachineItem {
    $type: "metal_machine";
    location: string;
}

function metalFromData(data: AdminMetalMachineItem, location: string): AdminMetalMachineItemLoc {
    return { ...data, $type: "metal_machine", location };
}

export type Response = Map<AdminMetalMachineItemLoc["uuid"], AdminMetalMachineItemLoc>;

//#endregion

//#region Query and parameters
export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["metal_machine/list"] : ["metal_machine/list", params.location];
}

export interface Params {
    location: string;
}

export function getQuery(client: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            return mapFromEntries(
                getResponseData(
                    await client.GET("/{location}/admin/metal/machines", {
                        signal,
                        params: { path: { location } },
                    }),
                ),
                (metal) => [metal.uuid, metalFromData(metal, location)] as const,
            );
        },
    });
}
//#endregion

//#region Atoms
export const metalMachineLocationQueryAtom = atomFamily((location: string) =>
    atomFromStandardQueryOptions(getQuery, { location }),
);

export const metalMachineQueryAtom = atomAllLocationsQuery(metalMachineLocationQueryAtom, (results) =>
    mergeMaps(results),
);
//#endregion

//#region Mutations

export const machineCreateMutation = adminMutation((apiClient) => ({
    entity: "machine",
    operation: "create",
    requestFn: async ({ body, location }: { body: AdminMetalMachineCreateBody; location: string }) =>
        await apiClient.POST("/{location}/admin/metal/machines", {
            ...jsonEncodedBody,
            body,
            params: { path: { location } },
        }),
    invalidateQueryKey: getQueryKey,
}));

export const machineUpdateMutation = adminMutation((apiClient) => ({
    entity: "machine",
    operation: "update",
    requestFn: async ({
        body,
        location,
        uuid,
    }: {
        body: AdminMetalMachineUpdateBody;
        location: string;
        uuid: string;
    }) =>
        await apiClient.PATCH("/{location}/admin/metal/machines/{uuid}", {
            ...jsonEncodedBody,
            body,
            params: { path: { location, uuid } },
        }),
    invalidateQueryKey: getQueryKey,
}));

export const machineDeleteMutation = adminMutation((apiClient) => ({
    entity: "machine",
    operation: "delete",
    requestFn: async ({ location, uuid }: { location: string; uuid: string }) =>
        await apiClient.DELETE("/{location}/admin/metal/machines/{uuid}", {
            params: { path: { location, uuid } },
        }),
    invalidateQueryKey: getQueryKey,
}));

//#endregion

//#region Lease mutations

export const leaseCreateMutation = adminMutation((apiClient) => ({
    entity: "lease",
    operation: "create",
    requestFn: async ({ body, location }: { body: AdminMetalLeaseCreateBody; location: string }) =>
        await apiClient.POST("/{location}/admin/metal/leases", {
            ...jsonEncodedBody,
            body,
            params: { path: { location } },
        }),
    invalidateQueryKey: getQueryKey,
}));

export const leaseUpdateMutation = adminMutation((apiClient) => ({
    entity: "lease",
    operation: "update",
    requestFn: async ({ body, location, uuid }: { body: AdminMetalLeaseUpdateBody; location: string; uuid: string }) =>
        await apiClient.PATCH("/{location}/admin/metal/leases/{uuid}", {
            ...jsonEncodedBody,
            body,
            params: { path: { location, uuid } },
        }),
    invalidateQueryKey: getQueryKey,
}));

//#endregion
