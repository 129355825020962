import type { Key } from "react-aria-components";
import { sortBy } from "remeda";
import {
    WSelect,
    type WSelectProps,
    type WSelectStyleProps,
    type WSelectValueProps,
} from "../../../components/forms/WSelect.tsx";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { metalSpecQueryAtom, type MetalSpecLoc } from "./specQuery.ts";

export interface SpecSelectProps<TKey extends Key>
    extends WSelectStyleProps,
        WSelectValueProps<MetalSpecLoc, TKey>,
        // Allow customizing the `getKey` function (to use either `spec_id` or `uuid` as a key)
        Pick<WSelectProps<MetalSpecLoc, TKey>, "getKey"> {}

export function ASpecSelect<TKey extends Key>(props: SpecSelectProps<TKey>) {
    const specs = useSuspenseQueryAtom(metalSpecQueryAtom);
    return (
        <WSelect
            autoFocusSearch
            items={sortBy([...specs.values()], (l) => l.title)}
            getTextValue={(item) => item.title}
            className="min-w-30em"
            // Prevent `description` from overflowing beyond the bottom of an item
            itemClassName="overflow-hidden"
            {...props}
        >
            {(item) => (
                <div className="flex flex-col items-start">
                    <div>{item.title}</div>
                    <div className="text-muted font-size-small">{item.description}</div>
                </div>
            )}
        </WSelect>
    );
}
