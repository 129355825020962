import { link } from "../../../components/Action.tsx";
import { WToolButton } from "../../../components/button/WToolButton.tsx";
import { WModal } from "../../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../../modules/api/useStandardMutation.ts";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { ADeleteButton } from "../../ADeleteButton.tsx";
import { AdminList } from "../../AdminList.tsx";
import { AdminTable } from "../../AdminTable.tsx";
import { AEditButton } from "../specs/AEditButton.tsx";
import { machineItemFields } from "./machineItemFields.ts";
import { MachineItemModal } from "./MachineItemModal.tsx";
import { machineDeleteMutation } from "./machinesQuery.ts";
import { machinesWithOsAtom } from "./machinesWithOs.ts";

export function MachinesView() {
    return (
        <AdminList entityType="machine" addModal={<MachineItemModal />}>
            <AdminMachinesTable />
        </AdminList>
    );
}

function AdminMachinesTable() {
    const machines = useSuspenseQueryAtom(machinesWithOsAtom);
    const deleteMutation = useStandardMutation(machineDeleteMutation);

    return (
        <AdminTable
            items={[...machines.values()]}
            fields={machineItemFields}
            getId={(e) => e.uuid}
            actionButtons={(item) => (
                <>
                    <WToolButton
                        icon="i-lucide:history"
                        inTable
                        label="History"
                        action={link({
                            to: "/admin/metal/machines/history/$location/$uuid",
                            params: { uuid: item.uuid, location: item.location },
                        })}
                    />

                    <WModal button={<AEditButton />}>
                        <MachineItemModal item={item} />
                    </WModal>
                    <ADeleteButton entityType="machine" deleteFn={async () => await deleteMutation.mutateAsync(item)} />
                </>
            )}
        />
    );
}
