import { NA } from "../../AdminTable.tsx";
import type { ItemOsInfo } from "./machinesWithOs.ts";

export function OsViewer({ value }: { value: ItemOsInfo }) {
    return value.os ? (
        <>
            {value.os_type!.title} {value.os.version_title}
        </>
    ) : (
        <NA />
    );
}
