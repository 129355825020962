import type { AdminMetalMachineCreateBody, MetalStateHistory } from "@warrenio/api-spec/spec.oats.gen";
import { Controller, useForm } from "react-hook-form";
import { keys, pick } from "remeda";
import { controllerProps, formPropsToFormFieldProps } from "../../../components/forms/ariaController.tsx";
import { FormField } from "../../../components/forms/FormField.tsx";
import { useStandardMutation } from "../../../modules/api/useStandardMutation.ts";
import type { LocationInputs } from "../../../modules/location/LocationField.tsx";
import { useLocationsForType } from "../../../modules/location/query.ts";
import { changedFields } from "../../../utils/changedFields.ts";
import { AdminLocationField } from "../../AdminLocationField.tsx";
import { AFTextField } from "../../form/AdminFormFields.tsx";
import { WAdminModalCrudForm } from "../../form/AdminModalContent.tsx";
import { ATextArea } from "../../form/Fields.tsx";
import { UuidField } from "../../form/UuidField.tsx";
import { AdminMetalOsSelect } from "../AdminMetalOsSelect.tsx";
import { ASpecSelect } from "../specs/SpecSelect.tsx";
import { machineItemFields } from "./machineItemFields.ts";
import { machineCreateMutation, machineUpdateMutation } from "./machinesQuery.ts";
import type { AdminMachineWithOs } from "./machinesWithOs.ts";

export function MachineItemModal({ item }: { item?: AdminMachineWithOs | undefined }) {
    interface Inputs extends AdminMetalMachineCreateBody, LocationInputs {
        os_id: MetalStateHistory["os_id"];
    }

    //#region Hooks
    const locations = useLocationsForType("bare_metal");

    const createDefaults: Inputs = {
        location: locations.defaultLocation,

        label: "",
        os_id: null,
        admin_notes: "",
        ssh_credentials: null,
        // NB: TypeScript hack, there's a RHF validator set on this field to make sure it's not `undefined`
        spec_id: undefined!,
        mac_addresses: [],
        ip_public_v4: "",
        ip_public_v6: null,
    };

    const form = useForm<Inputs>({
        defaultValues: item ? pick(item, keys(createDefaults)) : createDefaults,
    });
    const { control } = form;

    const createMutation = useStandardMutation(machineCreateMutation);
    const updateMutation = useStandardMutation(machineUpdateMutation);

    //#endregion

    async function onSubmit({ location, ...body }: Inputs) {
        if (item) {
            const changed = changedFields(item, body);
            if (changed) {
                await updateMutation.mutateAsync({
                    body: changed,
                    location,
                    uuid: item.uuid,
                });
            }
        } else {
            await createMutation.mutateAsync({ body, location });
        }
    }

    const isEdit = !!item;
    return (
        <WAdminModalCrudForm entityType="machine" isUpdate={isEdit} form={form} onSubmit={onSubmit}>
            <UuidField value={item?.uuid} />

            <AdminLocationField locations={locations} isUpdate={isEdit} />

            <AFTextField control={control} name="label" isRequired config={machineItemFields.label} />

            <Controller
                control={control}
                name="os_id"
                render={(p) => (
                    <FormField wide label={machineItemFields.os.title} {...formPropsToFormFieldProps(p)}>
                        <AdminMetalOsSelect
                            defaultValueKey={p.field.value}
                            onChange={(_, key) => p.field.onChange(key)}
                        />
                    </FormField>
                )}
            />

            <Controller
                control={control}
                name="spec_id"
                {...controllerProps({ isRequired: true })}
                render={(p) => (
                    <FormField
                        wide
                        isRequired
                        label={machineItemFields.spec_title.title}
                        {...formPropsToFormFieldProps(p)}
                    >
                        <ASpecSelect
                            getKey={(i) => i.spec_id}
                            defaultValueKey={p.field.value}
                            onChange={(_, key) => p.field.onChange(key)}
                        />
                    </FormField>
                )}
            />

            <AFTextField control={control} name="ip_public_v4" isRequired config={machineItemFields.ip_public_v4} />
            <AFTextField control={control} name="ip_public_v6" nullable config={machineItemFields.ip_public_v6} />

            <AFTextField control={control} name="admin_notes" config={machineItemFields.admin_notes}>
                <ATextArea />
            </AFTextField>

            <AFTextField control={control} name="ssh_credentials" nullable config={machineItemFields.ssh_credentials} />
        </WAdminModalCrudForm>
    );
}
