import type { MetalOs, MetalOsType } from "@warrenio/api-spec/spec.oats.gen";
import { mapOnValues } from "@warrenio/utils/collections/maps";
import { atom } from "jotai/vanilla";
import { metalOsQueryAtom } from "../../../modules/metal/metalOsQuery.ts";
import { mergeLoadedQueries } from "../../../utils/query/mergeQueries.ts";
import { type AdminMetalMachineItemLoc, metalMachineQueryAtom } from "./machinesQuery.ts";

export interface ItemOsInfo {
    os_type: MetalOsType | undefined;
    os: MetalOs | undefined;
}

export interface AdminMachineWithOs extends AdminMetalMachineItemLoc, ItemOsInfo {}

export const machinesWithOsAtom = atom((get) => {
    const machines = get(metalMachineQueryAtom);
    const osData = get(metalOsQueryAtom);

    return mergeLoadedQueries({ machines, osData }, ({ machines, osData }) =>
        mapOnValues(machines, (item): AdminMachineWithOs => {
            const os = osData.os.find((o) => o.os_id === item.os_id);
            const os_type = osData.os_types.find((o) => o.os_type_id === os?.os_type_id);
            return { ...item, os_type, os };
        }),
    );
});
