import LR from "../../components/LeftRight.module.css";

import type { MetalOs } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { sortBy } from "remeda";
import type { LiteralUnion } from "type-fest";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect, type WSelectStyleProps, type WSelectValueProps } from "../../components/forms/WSelect.tsx";
import { DataIcon } from "../../components/icon/DataIcon.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { metalOsQueryAtom } from "../../modules/metal/metalOsQuery.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";

export interface MetalOsSelectItem extends MetalOs {
    title: string;
    icon: string;
}

export interface AdminMetalOsSelectProps
    extends WSelectStyleProps,
        WSelectValueProps<MetalOsSelectItem | null, LiteralUnion<MetalOsSelectItem["os_id"], "__none__">> {}

export function AdminMetalOsSelect(props: AdminMetalOsSelectProps) {
    const { os, os_types } = useSuspenseQueryAtom(metalOsQueryAtom);

    const items = os.map((item): MetalOsSelectItem => {
        const osType = notNull(os_types.find((ot) => ot.os_type_id === item.os_type_id));

        return {
            ...item,
            title: `${osType.title} ${item.version_title}`,
            icon: osType.icon,
        };
    });

    return (
        <WSelect
            autoFocusSearch
            getKey={(item) => item?.os_id ?? "__none__"}
            items={[null, ...sortBy(items, (l) => l.os_id)]}
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select Operating system"
            getTextValue={(item) => item?.title ?? "None"}
            {...props}
        >
            {(item) => {
                const title = item?.title ?? <i>None</i>;
                const icon = item?.icon;
                return (
                    <LeftRight
                        icon={
                            icon ? (
                                <DataIcon data={icon} mode="mask" className="color-primary size-0.75rem" />
                            ) : (
                                <MaskIcon className="jp-host-icon color-primary size-0.75rem" />
                            )
                        }
                        title={title}
                    />
                );
            }}
        </WSelect>
    );
}
